import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import throttle from "lodash/throttle";

const LatestRoll = ({ data }) => {
  const [postsToShow, setPostsToShow] = useState(() => {
    if (typeof window !== `undefined`) {
      return window.postsToShow || 6
    }
    return 6
  })
  const [showingMore, setShowingMore] = useState(postsToShow > 6)

  const throttledUpdate = throttle(update, 200)

  function update() {
    const distanceToBottom =
      document.documentElement.scrollHeight -
      (window.scrollY + window.innerHeight)

    if (showingMore && distanceToBottom < 225) {
      setPostsToShow(postsToShow + 18)
      console.log('Updating postsToShow:', postsToShow);
    }

    throttledUpdate.ticking = false
  }

  const handleScroll = () => {
    if (!throttledUpdate.ticking) {
      throttledUpdate.ticking = true
      requestAnimationFrame(() => update())
      console.log('handleScroll called');
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
      window.postsToShow = postsToShow
    }
  })

  const posts = data.allMarkdownRemark.edges

  return (
    <div className="pt-4 mb-6 px-2">
      <div className="grid gap-8 xl:gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
        {posts.slice(0, postsToShow).map(({ node: post }) => (
          <Link to={post.frontmatter.path} className="h-full" key={post.id}>
            <div className="flex flex-col h-full shadow-lg hover:shadow-xl bg-white hover:bg-gray-50 transition duration-150 rounded">
              {post.frontmatter.featuredimage && (
                <div className="flex justify-center">
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "56.25%",
                    }}
                  >
                    <img
                      className="object-cover pointer-events-none absolute top-0"
                      src={require("../../static/" +
                        post.frontmatter.featuredimage)}
                      alt={post.frontmatter.title}
                    />
                  </div>
                </div>
              )}

              <div className="flex flex-col tracking-wider justify-between p-5">
                <div className="text-lg mt-2 lg:text-xl 2xl:text-2xl leading-6 text-gray-900">
                  {post.frontmatter.title}
                </div>
                <div className="text-sm 2xl:text-base mt-3 text-gray-500">
                  <time dateTime="2020-03-16">{post.frontmatter.date}</time>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      {postsToShow < posts.length && !showingMore && (
        <div className="flex justify-center mt-12 mb-8">
          <button
            id="loadMoreBlog"
            className="px-5 py-3 bg-white shadow rounded border border-new-purple text-new-purple hover:bg-new-purple text-white hover:text-white transition duration-150"
            onClick={() => {
              setPostsToShow(prevPostsToShow => prevPostsToShow + 18)
              setShowingMore(true)
            }}
          >
            <h3 className="flex mx-auto tracking-wider text-lg 2xl:text-xl">
              Load More Blogs
            </h3>
          </button>
        </div>
      )}
    </div>
  )
}

LatestRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query LatestRollQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              visibility: { eq: true }
              category: { ne: "stories" }
            }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          edges {
            node {
              id
              frontmatter {
                category
                date(formatString: "DD MMMM YYYY")
                featuredimage
                path
                title
                visibility
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <LatestRoll data={data} count={count}/>}
  />
)
